import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLOR } from '@/app/common/style/variables';
import { tabletAndBelow, mobile, desktopDown } from '@/app/common/style/mixins';
import { rem } from '../common/style/functions';

const ServicesSectionStyled = styled.div`
  padding: 64px 0 80px;
  background-color: ${COLOR.woodSmoke1};

  .container {
    display: flex;
    flex-direction: column;

    h2,
    h3,
    h4 {
      color: ${COLOR.woodSmoke5}; //$color-wood-smoke-5;
    }

    p {
      color: ${COLOR.woodSmoke4}; //$color-wood-smoke-4;
    }
  }

  .section-header {
    h2 {
      margin-bottom: 0;

      ${mobile(css`
        margin-bottom: 0;
      `)}
    }
  }

  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h2 {
      ${mobile(css`
        margin-bottom: 0;
      `)}
    }

    > .service {
      width: 65%;

      ${mobile(css`
        width: 100%;
        margin-bottom: 56px;
      `)}

      &:first-of-type {
        width: 25%;

        ${mobile(css`
          width: 100%;
        `)}

        p {
          margin-top: 32px;

          ${mobile(css`
            margin-top: 0;
          `)}
        }
      }

      h3 {
        margin-bottom: 32px;
      }

      .service-link-container {
        a.service-link {
          display: flex;
          align-items: center;
          padding: 37px;
          border: 1px solid ${COLOR.woodSmoke3};
          border-radius: 12px;
          max-height: 102px;
          margin-bottom: 24px;

          ${mobile(css`
            padding: 20px;
          `)}

          &:hover {
            background-color: #151818;

            &::after {
              margin-right: 0;
              background-position: right center;
            }
          }

          span {
            margin-right: 32px;

            ${mobile(css`
              margin-right: 20px;
            `)}
          }

          &::after {
            content: '';
            background-image: url('/assets/images/arrow-right-alt.svg');
            background-repeat: no-repeat;
            background-position: left center;
            width: 25px;
            height: 16px;
            margin-left: auto;

            ${mobile(css`
              margin-left: 10px;
            `)}
          }

          h3,
          p {
            margin-top: 0;
            margin-bottom: 0;
          }

          h3 {
            width: 250px;

            ${mobile(css`
              width: auto;
              font-size: 22px;
              line-height: 30px;
            `)}
          }

          p {
            width: 230px;
            font-size: 16px;

            ${mobile(css`
              width: auto;
              font-size: 14px;
              line-height: 22px;
            `)}
          }

          .service-link-icon {
            display: block;
            width: 40px;
            height: 40px;
          }

          .service-link-content {
            display: flex;
            align-items: center;

            ${mobile(css`
              display: block;
              width: 90%;
            `)}
          }
        }
      }
    }
  }

  .action {
    margin-top: 56px;
    align-self: flex-end;
    margin-top: 56px;
  }

  ${tabletAndBelow(css`
    padding: 40px 0 48px;

    .section-header {
      h2 {
        margin-bottom: 24px;
      }
    }

    .services > .service {
      h3 {
        margin-bottom: 16px;
      }
    }

    .action {
      align-self: flex-start;
      margin-top: 40px;
    }
  `)}

  .blocks-section-container {
    padding-top: 63px;
    padding-bottom: 89px;

    ${mobile(css`
      padding-top: 41px;
      padding-bottom: 56px;
    `)}

    &.has-featured-block {
      padding-top: 104px;
      padding-bottom: 105px;

      ${mobile(css`
        padding-top: 56px;
        padding-bottom: 90px;
      `)}
    }

    .section-featured-content {
      p {
        color: ${COLOR.woodSmoke3}; //$color-wood-smoke-3;
      }

      .content {
        display: flex;
        align-items: center;
        padding-top: 15px;

        ${mobile(css`
          margin-top: 9px;
        `)}
      }

      + h2 {
        margin-top: 104px;

        ${mobile(css`
          margin-top: 52px;
        `)}
      }
    }

    h2 {
      font-weight: 300;
      letter-spacing: 0;
      margin-bottom: 40px;

      ${mobile(css`
        letter-spacing: 0.5px;
        margin-bottom: 31px;
      `)}
    }

    .section-block {
      align-items: center;

      &:nth-of-type(n + 3) {
        margin-top: 90px;
      }

      ${desktopDown(css`
        &:nth-of-type(n + 2) {
          margin-top: 40px;
        }

        &:nth-of-type(even) {
          .row {
            display: flex;
            flex-flow: row-reverse;

            .image-container {
              margin-left: auto;
            }
          }
        }
      `)}

      .col-block-text-container {
        display: flex;
        align-items: center;

        .block-text-container {
          padding-top: 20px;

          ${mobile(css`
            padding-top: 0;
            margin-top: -8px;
          `)}

          .block-title {
            margin-bottom: 16px;

            ${mobile(css`
              font-size: ${rem(16)};
              line-height: 26px;
              margin-bottom: 8px;
              letter-spacing: 0;
            `)}
          }

          p,
          a {
            font-size: 16px;
            line-height: 26px;
            color: ${COLOR.woodSmoke3}; //$color-wood-smoke-3;

            ${mobile(css`
              font-size: ${rem(14)};
              line-height: 22px;
              letter-spacing: 0;
            `)}
          }

          &.darker-text {
            p,
            a {
              color: ${COLOR.woodSmoke2}; // $color-wood-smoke-2;
            }
          }
        }
      }
    }
  }
`;
export default ServicesSectionStyled;
